jQuery(function($){
    if ($('body.template-alojamento').length > 0)
    {
        /**************************
     * Listar Alojamento
     *****************/
    var page = 1;
    var slug = $('.list-group-item-alojamento.active').data('slug');
    var termid = $('.list-group-item-alojamento.active').data('termid');
    var location = $('.list-group-item-alojamento.active').data('location');
    //var taxname = $('.list-group-item.active').data('taxname');
    var listarAlojamentoAjax = function (page, termid, slug, location) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarAlojamento',
                page: page,
                termid:termid,
                slug:slug,
                location:location
            },
            beforeSend:function () {
               console.log('A carregar alojamento');
                $('.loader').addClass('loading');
                $('.progress').removeClass('d-none');
                //$("body").addClass('block-scroll'); 
               
                
            }
        })
        .done(function(response){
            console.log('alojamento carregados');
           
            $("main").toggleClass('block-scroll'); 
            
            $('.progress').addClass('d-none');
           // $("body").removeClass('block-scroll'); 
           
            setTimeout(function() {
                //alert("Hello");
               // $('.casos-modal').removeClass('d-block');
               //$('#listar-posts-casos').removeClass('ad-fixed-height');
               $('.loader').removeClass('loading');
               
            }, 5000);
            $('#listar-alojamento').html(response);
            
        })
        .fail(function(){
            console.log('opss erro a carregar posts!!!');
        });
    };
    listarAlojamentoAjax(page, termid,slug,location);

    //Ação do botão da categoria
    $('.list-group-item-alojamento').on('click', function () {
       // alert ("teste");
       slug = $(this).data('slug');
       banner= $(this).data('banner');
       console.log(banner);
       $('.main-banner').fadeTo('fast',0)
       setTimeout(function() {
            $('.main-banner').css({'background-image': 'url(' + banner + ')'});
            $('.main-banner').fadeTo('fast',1);
       
        }, 100);
       
      // $( '.main-banner' ).animate({'background-image':'url(' + banner + ')'}, 5000);
       //console.log(slug);
       termid = $(this).data('termid');
       //taxname = $(this).data('taxname');
       listarAlojamentoAjax(1, termid,slug,location);
       $('.list-group-item-alojamento').removeClass('active');
       $(this).addClass('active');
    });


    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
        var slug = $('.list-group-item-alojamento.active').data('slug');
        listarAlojamentoAjax(page, termid, slug,location);
        //console.log(slug);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });


   
    /******************************************************************************** */
    }
    


    

});